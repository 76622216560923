import { AquaStyled } from "./style";
import aqua from "../../asserts/images/aqua.jpg";
import pdf1 from "../../asserts/images/AGC_Global_Informativo Técnico 001.pdf";
import pdf2 from "../../asserts/images/AGC_Global_Informativo Técnico 002.pdf";
import pdf3 from "../../asserts/images/AGC_Global_Informativo Técnico 003.pdf";
import pdf4 from "../../asserts/images/AGC_Global_Informativo Técnico 004.pdf";
import pdf5 from "../../asserts/images/AGC_Global_Informativo Técnico 005.pdf";
import pdf6 from "../../asserts/images/Use of probiotic consortium technology.pdf";

export function Aqua() {
  return (
    <AquaStyled>
      <div className="nav-links">
        <a href={pdf1} target="_blank">
          Ambiente de produção
        </a>

        <a href={pdf2} target="_blank">
          O uso de biológicos
        </a>
        <a href={pdf3} target="_blank">
          Manejo produtivo
        </a>
        <a href={pdf4} target="_blank">
          Efeito dominó
        </a>
        <a href={pdf5} target="_blank">
          Atividade enzimática
        </a>
        <a href={pdf6} target="_blank">
          Uso de TCP
        </a>
        <a
          href="https://www.seafoodbrasil.com.br/tcp-se-apresenta-como-nova-opcao-ao-setor-aquicola"
          target="_blank"
        >
          Opção aquícola
        </a>
      </div>
      <a
        target="_blank"
        href="https://www.youtube.com/playlist?list=PLehUS8OHrDkx6zIt88a193tsJvhoUGrhv"
      >
        <img src={aqua} alt="" />
      </a>
    </AquaStyled>
  );
}
