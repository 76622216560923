import styled from "styled-components";

export const AquaStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;

  img {
    width: 370px;
    height: 70px;
  }

  .nav-links {
    display: flex;
    max-width: 100%;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .nav-links a {
    padding: 10px;
    border-radius: 8px;
    background-color: #FFC300 ;
    color: #000000;
    font-weight: 700;
  }

  @media (max-width: 638px) {
    .nav-links a {
      font-size: 12px;
    }
  }
`;
